import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { FluidImg } from 'src/embeds/image/image-dato';
import { FlowGrid, Stack, Stop } from 'src/system';

const MyFlowGrid = styled(FlowGrid)`
  & > :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 800px) {
      order: var(--flip-order);
    }
  }
  & > :nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (min-width: 800px) {
      width: 500px;
    }
  }
`;

const Wrap = styled.div`
  max-width: ${({ theme }) => theme.largeContentWidth};
  margin: 0 auto;
  padding: 60px 1rem;
`;

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  return (
    <MDXProvider components={MDXComponents}>
      <Wrap as="section">
        <Stack style={{ '--gap': '80px' }}>
          <header style={{ textAlign: 'center' }}>
            <h2>{heading}</h2>
            {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
          </header>
          {callouts.map((callout, i) => {
            return (
              <Stop key={callout.heading}>
                <MyFlowGrid as="article" style={{ '--gap-x': '4rem' }}>
                  <div className="span-auto" style={{ '--flip-order': i % 2 == 1 ? 0 : 1 }}>
                    <h3 style={{ fontSize: '1.6rem' }}>{callout.heading}</h3>
                    {callout.content && <MDXRenderer>{callout.contentNode.childMdx.body}</MDXRenderer>}
                  </div>
                  <div>
                    {callout.media ? (
                      <FluidImg data={callout.media} maxWidth={500} />
                    ) : (
                      <pre style={{ maxWidth: 500 }}>
                        <code>missing image</code>
                      </pre>
                    )}
                  </div>
                </MyFlowGrid>
              </Stop>
            );
          })}
          {ctas.length > 0 && (
            <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
              {ctas.map((cta, i) => {
                const ctaFlags = i > 0 ? { secondary: true } : {};
                return (
                  <li key={i}>
                    <Button to={cta.url} {...ctaFlags}>
                      {cta.text}
                    </Button>
                  </li>
                );
              })}
            </InlineList>
          )}
        </Stack>
      </Wrap>
    </MDXProvider>
  );
}
